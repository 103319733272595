import BaseResource from 'resources/base-resource';
import axios from 'axios';

class Document extends BaseResource {
  static baseUrl = '/documents/:id.json';

  static bulkCreate(data) {
    return axios.post('/documents/bulk_create.json', data);
  }

  static upload(file, onUploadProgress) {
    const params = { file: file.name, name: file.name, documentSource: 'Priori' };

    return Document.save({ document: params })
      .then(document => {
        const formData = new FormData();
        formData.append('document[file]', file);

        return Document.putForm({ id: document.id, document: { file: file } }, { onUploadProgress })
          .catch((err) => {
            Document.delete({ id: document.id });
            throw (err);
          });
      });
  }

  static updateEngagementLetter(role, matterId, file, signature, coord) {
    let formData = new FormData();
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    if (role) {
      formData.append('document[role]', role);
    }

    if (file) {
      formData.append('document[file]', file);
    }

    if (signature) {
      var lawyerSignatureFile = Document.engagementLetterSignatureToBlob(signature);
      formData.append('document[signature]', lawyerSignatureFile);
    }

    if (coord) {
      formData.append('document[coord]', coord);
    }

    formData.append('document[matter_id]', matterId);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) { return; }

        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        }
        else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };

      xhr.onerror = () => {
        reject({ status: this.status, statusText: xhr.statusText });
      };

      xhr.open('POST', '/documents/update_engagement_letter.json');
      xhr.setRequestHeader('x-csrf-token', csrfToken);

      xhr.send(formData);
    });
  }

  static engagementLetterSignatureToBlob(b64Data) {
    let arr = b64Data.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'signature.png', { type: mime });
  }

  readOnly() {
    return this.documentReceipts[0].role === 'reader';
  }

  permissionDisplay() {
    return this.readOnly() ? 'Read Only' : 'Read/Write';
  }

  onGoogleDrive() {
    return this.documentSource === 'Google';
  }

  hasNote() {
    return this.note && this.note.trim().length;
  }
}

export default Document;
